<template>
  <router-link
    class="d-flex"
    :to="to"
  >
    <img
      :src="logoUrl"
      :alt="hasCustomLogo ? workspace.name : 'zazos'"
      class="topbar-logo__img"
      :class="{ 'topbar-logo__img--small': !hasCustomLogo }"
    >
  </router-link>
</template>

<script>
import { defineComponent } from 'vue';
import { mapGetters, mapState } from '~/assets/javascript/modules/vuex';

export default defineComponent({
  name: 'TheTopbarLogo',
  props: { to: { type: [String, Object], default: null } },
  setup() {
    return {
      zazos: useZazos(),
      ...mapState('workspace', ['workspace']),
      ...mapGetters('workspace', { workspaceLogoUrl: 'logoUrl' }),
    };
  },
  computed: {
    logoUrl() {
      return this.workspaceLogoUrl || this.zazos.logoPath.value;
    },
    hasCustomLogo() {
      return this.logoUrl !== this.zazos.logoPath.value;
    },
  },
});
</script>

<style lang="scss" scoped>
.topbar-logo__img {
  height: 42px;
  max-width: 100%;
  width: auto;
}

.topbar-logo__img--small {
  height: 32px;
}

</style>
